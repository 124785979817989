@import '../../assets/styles/variables';

// ------------
// containers
// ------------

.portfolioDetails {
  .okButton {
    background-color: $primaryColor;
  }

  hr {
    border-top: 0.5px solid $gray4;
  }
  section {
    margin: 2rem 0rem 3rem;
  }
}

.linkContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;
}

// ------------
// back
// ------------

.back {
  color: $gray4;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: $gray6;
    svg path {
      fill: $gray6;
    }
  }

  svg {
    path {
      fill: $gray4;
    }
    margin-right: 0.8em;
  }
}

// ------------
// link
// ------------

.link {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.14rem;
  height: 1.14rem;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: $blue1;

  &:hover {
    color: $blue2;
  }
}
