@import '../../assets/styles/variables';

.errorBoundary {
  margin-bottom: 2rem;
}

.infoContainer {
  h5 {
    font-weight: 700;
    margin-top: 1rem;
  }
  h6 {
    color: $gray7;
  }
  button {
    margin-top: 2rem;
  }
  .customNotification {
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
      margin: 1%;
    }
  }
}
