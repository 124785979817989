@import '../../assets/styles/variables';

// ------------
// back
// ------------

.back {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: $gray4;
  text-decoration: none;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: $gray6;
    svg path {
      fill: $gray6;
    }
  }

  svg {
    path {
      fill: $gray4;
    }
    margin-right: 0.8em;
  }
}
