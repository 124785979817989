@import '../../assets/styles/variables';

footer {
  background: $footerBgGradient;
  min-height: 6.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  div:first-child {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .appFooterLinks {
    a {
      color: $white1;
      font-size: 0.875rem;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.appFooterLinks {
  display: flex;
  align-items: center;
  color: $white1;
  width: 100%;
  gap: 1.5rem;
  line-height: 0.5rem !important;
}

.appFooterMobileLinks {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

@media (max-width: 900px) {
  .appFooterLinks {
    flex-direction: column;
    padding: 1rem 0rem;
  }
  .appFooterMobileLinks {
    margin-left: unset;
  }
}
