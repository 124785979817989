@import '../transactions/index.module.scss';

.infoContainer {
  & > div {
    justify-content: space-between !important;
    div:first-child {
      color: $gray7;
    }
    div:last-child {
      text-align: right !important;
      font-weight: 700 !important;
    }
  }
}
