@import '../../assets/styles/variables';

.tableWrapper {
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Roboto';
  margin-bottom: '30px';
  .rowTitle {
    color: $gray8;
  }
  .rowData {
    color: $gray8;
  }
  .tableHeader {
    background-color: $gray2;
    color: $gray8;
  }
}
