$primaryColor: #009b7c;
$secondaryColor: rgb(109, 109, 177);
$gray1: #efefef;
$gray2: #eaeaea;
$gray3: #d5d5d5;
$gray4: #a6a6a6;
$gray5: #aaaaaa;
$gray6: #959595;
$gray7: #808080;
$gray8: #6b6b6b;
$gray9: #555555;
$gray10: #404040;
$red: #fa2e2e;
$green: #05b28e;
$blue1: #4d1cff;
$blue2: #3856ff;
$blue3: #0278dc;
$blue4: #3da4fd;
$white1: #ffffff;
$white2: #e4e4e4;
$black: #000000;
$footerBgGradient: #009b7c;
// Modal Colors
$backgroundColorPrimary: rgb(0, 0, 0);
$backgroundColorPrimaryOpacity: rgba(0, 0, 0, 0.4);
$contentbgcolor: #fefefe;
$closeColor: #aaa;

// global variables
$downloadReportsImgSize: 1.3rem;
