@import '../../assets/styles/variables';

.securityCode {
  color: $gray6;
  font-weight: 700;
  font-size: 1.375rem;
  margin-top: 0.5rem;
}

.securityName {
  font-weight: 700;
  font-size: 1.75rem;
}

.securityInfo {
  margin-top: 1rem;
}

.securityInfoColumn {
  .title {
    color: $primaryColor;
    font-weight: 500;
    font-size: 0.875rem;
  }
  .desc {
    font-weight: bold;
    font-size: 19px;
    margin-top: 0.5rem;
  }
}

.firstColumn {
  text-transform: uppercase;
  .title {
    font-weight: 700;
  }
}

.line {
  width: 0.5px;
  height: 2.375rem;
  background-color: $gray3;
}

.allTransactions {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  color: $gray7;
  margin: 0.5rem 0rem 1rem;
  padding-top: 20px;
}
