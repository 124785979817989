@import './variables';

.indexPopover {
  .MuiPopover-paper {
    border-radius: 0.875rem;
  }
  .benchmarkSubHeading {
    font-size: 0.75rem;
  }
  hr {
    border: 0.5px solid $gray3;
    margin: 1rem 0 0 0;
  }
  .MuiFormControlLabel-root {
    justify-content: space-between;
    margin-left: 0;
    padding: 1rem 0;
    width: 100%;
    border-bottom: 2px solid $gray3;
    .MuiTypography-root {
      font-size: 0.875rem;
      font-weight: 500;
      padding-right: 0.5rem;
    }
    .MuiRadio-root {
      padding: 0;
    }
  }
  .MuiFormControlLabel-root:last-child {
    border-bottom: none;
  }
}
