@import '../../assets/styles/variables';

.iconHeading {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 1rem;

  div {
    margin-left: 1rem;
  }

  h2 {
    margin: 0px;
    text-transform: uppercase;
  }

  p {
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: 0.25px;
    color: $gray8;
    margin: 0px;
  }
}
