@import '../../assets/styles/variables';

$maxFormWidth: 567px;

.onboarding {
  max-width: 700px !important;
  flex-direction: column;
  margin-bottom: 3rem;

  h4 {
    font-size: 2.25rem !important;
    font-weight: 700 !important;
    margin-bottom: 4.25rem;
  }
  p {
    font-size: 1.125rem !important;
  }
  h6 {
    color: $primaryColor !important;
  }
  .label {
    font-size: 1rem !important;
    color: $gray7 !important;
  }
  input {
    padding: 0.625rem 1.125rem !important;
  }
}

.btn {
  background-color: $primaryColor !important;
  text-transform: capitalize !important;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
}

.form {
  max-width: $maxFormWidth;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-weight: 700;
}

.helperText {
  font-size: 1rem !important;
  font-style: italic;
  margin-left: -0.875rem !important;
}
.grayHelperText {
  font-size: 1rem !important;
  font-style: italic;
  color: $gray6;
  margin-left: -0.875rem !important;
}

.agreement {
  margin-top: 1rem !important;
  color: $gray4;

  a {
    color: $gray4 !important;
    text-transform: lowercase;
  }
}
