@import '../../assets/styles/variables';

.appHeader {
  display: block;
  height: 8.6875rem;
  position: relative !important;

  // extra margin-below because the background image overflows down
  margin-bottom: 2rem;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #009b7c;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .appHeaderToolbar {
    align-items: center;
    min-height: 8.6875rem;
    padding: 0px;

    div {
      flex-grow: 1;
    }
  }
  .appHeaderUserProfile {
    flex-grow: 1;
    justify-content: end;
    cursor: pointer;

    .loginBtnInverted {
      background-color: white !important;
      color: $primaryColor !important;
    }
  }
  .content {
    min-height: 8.6875rem;
    color: $white1;
  }
  .contentBottom {
    min-height: 2.4375rem;
    bottom: 1.5625rem;
    position: relative !important;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

#menuAppbar {
  #navigationItemWrapper {
    color: $primaryColor;
  }
  .loginBtn {
    min-width: 6rem;
  }
}

.mobileNavMenu {
  svg path {
    fill: $gray7;
  }
}
