@import './variables';

// ------------
// rotate arrow
// ------------

.rotateArrow {
  transform: rotate(180deg);
}

// ------------
// spinning loader
// ------------

.spinningLoader {
  $borderWidth: 3px;

  border: $borderWidth solid transparent;
  border-top: $borderWidth solid $primaryColor;
  border-right: $borderWidth solid $primaryColor;
  border-radius: 50%;
  width: $downloadReportsImgSize;
  height: $downloadReportsImgSize;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
