@import '../../assets/styles/variables';

.portfolioCardWrapper {
  .portfolioCard {
    border: 1px solid $gray3;
    border-radius: 0.75rem;
    padding: 1rem;
    gap: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    .portfolioCardLeft {
      gap: 1rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .heading {
        font-weight: bold;
        line-height: 1rem;
        color: $gray4;
      }
      .netBalance {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.75rem;
        color: $black;
      }
      .headingReturn {
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: $gray4;
        text-transform: uppercase;
      }
      .netPortfolioReturn {
        font-style: normal;
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: $black;
        &.positiveReturnValue {
          color: $green;
        }
        &.negativeReturnValue {
          color: $red;
        }
      }
    }
    .portfolioCardRight {
      display: flex;
      align-items: center;
      .portfolioCardRightChevron {
        color: $gray3;
      }
    }
  }
}
