@import '../../assets/styles/variables';

$downloadImgSize: 1.1rem;

// ------------
// downloadBtn
// ------------

.downloadBtn {
  font-size: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.142rem;
    letter-spacing: 0.222222px;
    text-decoration: underline;
  }

  svg {
    width: $downloadImgSize;
    height: $downloadImgSize;
    margin-bottom: 0.2rem;

    path {
      stroke: $blue4;
    }
  }
}
