@import '../../assets/styles/variables';

// ------------
// containers
// ------------

.paginationComponent {
  display: flex;
  flex-direction: column;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9rem;
}

.paginateContainer {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0px;

  a {
    text-decoration: none;
  }
}

// ------------
// text
// ------------

.showPageEntries,
.nextText,
.previousText {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.142rem;
  height: 1.142rem;
  color: $gray8;
  text-decoration: none;

  a {
    color: $gray8;

    &:hover {
      color: $gray8;
    }
  }
}

.previousText {
  margin-right: 1.5rem;
}

.nextText {
  margin-left: 1.5rem;
}

// ------------
// active
// ------------

.active {
  a {
    width: 3.142rem;
    height: 3rem;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.142rem;
    letter-spacing: 0.222222px;
    background-color: $blue3;
    color: $white1 !important;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// ------------
// disabled
// ------------

.disabled {
  a {
    cursor: default;
    opacity: 0.5;
  }
}

// ------------
// page
// ------------

.page {
  width: 2.7rem;
  height: 2.6rem;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.142rem;
  text-align: right;
  color: $gray8;

  display: flex;
  align-items: center;
  justify-content: center;
}

// ------------
// no pagination
// ------------

@media (min-width: 900px) {
  .noPagination {
    height: 24.6875rem !important;
  }
}
