.navigationItemWrapper {
  margin: 1rem 1.5rem 1rem 0;
  font-size: 1rem;
  color: white;
  display: block;
  text-transform: none;
  .navigationItemContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    .navigationLeftIcon {
      margin-right: 0.5rem;
      display: flex;
    }
    .navigationName {
      margin-right: 0.5rem;
      display: flex;
    }
    .navigationRightIcon {
      display: flex;
    }
  }
}
.navigationLink {
  text-decoration: none;
}
