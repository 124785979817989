@import '../../assets/styles/variables';

.profilePage {
  hr {
    border-top: 1px solid $gray3;
  }
}

.heading {
  font-weight: 700;
  color: $gray6;
  opacity: 0.6;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}

.name {
  font-weight: 700;
  font-size: 1.25rem;
  color: $primaryColor;
  margin-bottom: 1rem;
}

.infoRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 1rem;
  margin-bottom: 2rem;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    color: $gray8;
  }
}

.changePasswordBtn {
  color: $primaryColor !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
}

.loadingBtn {
  padding-left: 2rem !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
}
