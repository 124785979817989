@import '../../assets/styles/variables';

// ------------
// line
// ------------

.line {
  height: 4.625rem;
  background-color: $gray4;
  width: 0.5px;
}
