@import '../../assets/styles/variables';

.homePage {
  max-width: 802px;

  h4 {
    font-size: 2.25rem !important;
    font-weight: 700 !important;
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 1rem;
    font-size: 1.125rem !important;
  }
}

.bottomContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.375rem;
  margin: 5.125rem 0rem 2rem;

  button {
    background-color: $primaryColor;
    width: 100%;
    max-width: 322px;
  }
}

.mobileLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.name {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $primaryColor !important;
}
