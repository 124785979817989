@import '../../assets/styles/variables';

// ------------
// containers
// ------------

.reportCard {
  background: $white1;
  border: 1px solid $gray3;
  box-shadow: 0px 0px 8px $gray3 !important;
  border-radius: 10px !important;
  padding: 0.875rem;
  cursor: pointer;
}

.bottomContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 1.29rem;

  img {
    cursor: pointer;
    width: $downloadReportsImgSize;
    height: $downloadReportsImgSize;
  }

  button {
    font-size: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    width: $downloadReportsImgSize;
    height: $downloadReportsImgSize;
  }
}
