@import '../../assets/styles/variables';

// ------------
// svgIcon
// ------------

.svgIcon {
  width: 1.3125rem;
  height: 1.3125rem;

  path {
    stroke: var(--color);
  }

  &:hover,
  &:active {
    path {
      stroke: var(--hover-color);
    }
  }
}
