@import '../../assets/styles/variables';

.dashboardContent {
  display: flex;
  flex-direction: column;
}

.portfolioContent {
  margin-bottom: 2rem;
}

.portfolioInfo {
  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;

  h5 {
    font-weight: 700;
    color: $gray6;
    opacity: 0.6;
    text-transform: uppercase;
  }
  .name {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: $primaryColor;
  }
  .netWorth {
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 2.625rem;
    color: $black;
  }
  .netReturn {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: $primaryColor;
  }
}

.clientName {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $primaryColor;
}

.portfolioChart {
  z-index: 0;

  div {
    margin-left: 0rem !important;
    margin-right: -1.2rem;
  }
}

.portfolioCards {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 2rem;
  cursor: pointer;
  z-index: 2;
}

@media (max-width: 900px) {
  .portfolioInfo {
    row-gap: 1rem;
  }
}

.test {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.14rem;
  height: 1.14rem;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: $blue1;

  &:hover {
    color: $blue2;
  }
}
