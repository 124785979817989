@import '../../assets/styles/variables';
@import './AssetCard.module.scss';

$assetClassesMaxWidth: 450px;

// ------------
// containers
// ------------

.allocationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 1rem 1rem !important;
  margin-bottom: 1.75rem !important;
  margin-top: 2rem !important;
  margin-right: 1rem !important;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.21);
  border-radius: 10px;
  position: relative;
  padding: $assetCardPadding;
  height: $assetCardHeight;
}

.assetsContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: $assetClassesMaxWidth;
}

.paginationOuterContainer {
  width: 100%;
  max-width: $assetClassesMaxWidth;
  justify-content: space-between;
}

// ------------
// allocationDate
// ------------

.allocationDate {
  margin-left: auto !important;
  color: $blue2;
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

// ------------
// arrow
// ------------

.arrow {
  margin-left: 1.125rem;
  margin-bottom: 0.2rem;
}

// ------------
// datePicker
// ------------

.datePickerInput {
  position: absolute !important;
  opacity: 0;
  right: -260px;
  pointer-events: none;
}

@media (max-width: 900px) {
  .datePickerInput {
    right: 0px;
  }
}
