@import '../../assets/styles/variables';

// ------------
// performanceCard
// ------------

.performanceCard {
  background: $white1;
  border: 1px solid $gray3;
  box-shadow: 0px 1.83754px 10.1064px 1.83754px $white2;
  border-radius: 0.875rem;
  padding: 1.4375rem 1.3125rem 1.1875rem;
  cursor: pointer;
}

.noCursor {
  cursor: default;
}

// ------------
// benchmark
// ------------

.benchmarkReturn {
  line-height: 1.334;
}
.benchmarkHeading {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
