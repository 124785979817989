@import '../../assets/styles/variables';

$contentMaxWidth: 370px;

.onboardingDone {
  display: flex !important;
  justify-content: center;
  margin-bottom: -120px;
}

.content {
  max-width: $contentMaxWidth;
  flex-direction: column;
  margin-top: 3.625rem;

  h4 {
    font-size: 2.25rem !important;
    font-weight: 700 !important;
  }
  p {
    font-size: 1.125rem !important;
  }
  h6 {
    color: $primaryColor !important;
  }
  .label {
    font-size: 1rem !important;
    color: $gray7 !important;
  }
  input {
    padding: 0.625rem 1.125rem !important;
  }
  button {
    background-color: $primaryColor;
    text-transform: capitalize;
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 1rem;
  }
}

.mobileLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.875rem;
  margin-top: 1.5rem;
}

.or {
  text-align: center;
  margin-top: 1rem;
}

.btn2 {
  background-color: $gray6 !important;
}

@media (max-width: 700px) {
  .onboardingDone {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .btn2 {
    margin-bottom: 1rem !important;
  }
}
