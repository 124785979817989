@import './custom.scss';
@import './muiOverrides.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pageHeader {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $primaryColor;
}

a {
  color: $primaryColor;
}
