@import '../../assets/styles/variables';

$assetCardHeight: 2.9375rem;
$assetCardPadding: 0 0.9375rem;

// ------------
// container
// ------------

.assetCard {
  background: $white1;
  border: 1px solid $gray3;
  box-shadow: 0px 0px 8px $gray3;
  border-radius: 0.625rem;

  // fixed values to ensure assets have same height as chart because chart needs fixed values
  height: $assetCardHeight;
  display: flex;
  align-items: center;
  padding: $assetCardPadding;
  cursor: pointer;
}

// ------------
// assetPercentage
// ------------

.assetPercentage {
  min-width: 3.5rem;
}

// ------------
// arrow
// ------------

.arrow {
  margin-left: 1.5rem;
}
