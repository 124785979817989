@import '../../assets/styles/variables';

.noticeParagraph {
  font-size: 1rem;
  font-family: 'Roboto';
  padding-top: 20px;
  padding-left: 25px;
}

.noticeUl {
  padding-top: 20px;
}

.noticeLi {
  margin-left: 25px;
  padding-top: 10px;
}
