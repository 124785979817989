@import '../../assets/styles/variables';

// ------------
// container
// ------------

.container {
  background: $white1;
  border: 1px solid $gray3;
  box-shadow: 0px 0px 8px $gray3;
  border-radius: 10px;
  padding: 1.4375rem 1.875rem;
  overflow: auto;
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 1.06rem;
    line-height: 1.18rem;
    height: 1.18rem;
  }
  .subheading {
    color: $gray6;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }
  .time {
    font-size: 0.875rem;
  }
  .description {
    font-size: 0.875rem;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: normal;
    padding-right: 1rem;
  }
  .paymentInfo {
    font-size: 0.875rem;
    .highlight {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      color: $blue2;
      margin-right: 0.25rem;
    }
  }

  .change {
    color: $gray6;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
  }
}
