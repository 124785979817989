@import '../../assets/styles/variables';

.adviserContent {
  .heading {
    font-weight: 700;
    color: $gray6;
    opacity: 0.6;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
  }
  .name {
    font-weight: 700;
    font-size: 2.25rem;
    color: $primaryColor;
    margin-bottom: 1rem;
  }
  .advisorButton {
    background-color: $primaryColor;
  }
  .infoRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 1rem;
    margin-bottom: 1.375rem;
    .infoText {
      font-style: normal;
      font-weight: bold;
      font-size: 0.875rem;
      color: $gray8;
    }
  }
}
