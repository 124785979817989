// ------------
// containers
// ------------

.container {
  margin-left: -3.2rem;
  margin-bottom: -2rem;
}

@media (max-width: 800px) {
  .container {
    margin-left: -3rem;
  }
}

@media (max-width: 600px) {
  .container {
    margin-left: -2.5rem;
  }
}
