.notificationBanner {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 13px 0;
  background-color: #e1ad01;
  justify-content: center;
  gap: 2%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
